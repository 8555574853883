//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../colors';

@mixin headlineColor($color: $primaryColor, $important: false)
{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
    {
        @if $important
        {
            color: $color !important;
        }
        @else
        {
            color: $color;
        }
    }
}
