//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.progressBarContainer
{
    position:         absolute;
    bottom:           80px;
    width:            100%;
    height:           5px;
    background-color: $pinkishGey;

    .progressBar
    {
        height:           100%;
        background-color: $scarlet;
    }
}
