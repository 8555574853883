@import '../../../../styles/all';

.videoOverlay
{
    position:         fixed;
    width:            100vw;
    height:           100vh;
    left:             0;
    top:              0;
    z-index:          $zIndexVideoOverlay;
    backdrop-filter:  blur(5px);
    background-color: $charcoalGrey10;
    padding:          45px 80px;
}

.videoBackground
{
    position:   absolute;
    width:      100%;
    height:     100%;
    object-fit: cover;
}

.contentOverlay
{
    position:        relative;
    width:           100%;
    height:          100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
}

.headline
{
    position:  absolute;
    font-size: 40px;
    top:       0;
    left:      0;
    margin:    20px 30px;
    color:     $scarlet;
}

.closeButton
{
    $closeButtonSize: 38px;

    position:         absolute;
    top:              0;
    right:            0;
    margin:           25px;
    width:            $closeButtonSize;
    height:           $closeButtonSize;

    svg
    {
        width:  $closeButtonSize;
        height: $closeButtonSize;

        @include svgColorFill($scarlet)
    }
}

.playPauseButton
{
    $playButtonSize: 38px;

    position:        absolute;
    left:            0;
    bottom:          0;
    margin:          15px 45px;
    width:           $playButtonSize;
    height:          $playButtonSize;

    svg
    {
        width:  $playButtonSize;
        height: $playButtonSize;

        @include svgColorFill($scarlet)
    }
}

.progressBarContainer
{
    position:         absolute;
    bottom:           80px;
    width:            100%;
    height:           5px;
    background-color: $pinkishGey;
}

.progressBar
{
    height:           100%;
    background-color: $scarlet;
}
