//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.videoDetailContainer
{
    flex:            1;
    display:         flex;
    flex-direction:  column;
    justify-content: flex-start;

    .videoClipspotsContainer
    {
        display:        flex;
        flex-direction: row;
    }

    .additionalButtonContainer
    {
        height: 100%;
        width:  440px;
        margin: 10px 0 10px 20px;

        > *
        {
            margin-bottom: 35px;
        }
    }
}
