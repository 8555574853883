//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.formWrapper
{
    position: absolute;
    inset:    0 0 0 0;
    display:  flex;
    flex:     1;
    margin:   auto;

    .loginForm
    {
        display:         flex;
        flex-direction:  column;
        justify-content: space-between;
        flex:            1;

        .inputContainer
        {
            display:        flex;
            flex-direction: column;
        }
    }
}



