@import '../../../../styles/all';

.editableText
{
    width:            372px;
    height:           210px;
    border-radius:    15px;
    background-color: #e8e8e8;
    padding:          10px;
    display:          flex;
    justify-content:  center;
    align-items:      center;
    cursor:           pointer;
    font-size:        24px;
    line-height:      1.17;
    color:            $charcoalGrey;
}

.input
{
    background:  transparent;
    border:      none;
    outline:     none;
    width:       100%;
    height:      100%;
    color:       inherit;
    font-size:   24px;
    font-family: $fontSourceGeneralBody;
    line-height: 1.17;
    display:     flex;
}

.text
{
    white-space: pre-wrap;
    height:      100%;
    width:       100%;
    overflow:    scroll;
}
