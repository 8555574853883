//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.alertBoxWrapper
{
    display:          flex;
    flex-direction:   column;
    align-items:      center;
    justify-content:  center;
    position:         fixed;
    top:              0;
    left:             0;
    flex:             1;
    width:            100vw;
    height:           100vh;
    visibility:       hidden;
    z-index:          $zIndeXAlertBoxWrapper;
    background-color: $charcoalGrey50;

    &.alertBoxWrapperVisible
    {
        visibility: visible;
    }

}
