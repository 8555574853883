//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.pageContainer
{
    min-width:  100vw;
    min-height: 100vh;

    .page
    {
        display:        flex;
        flex-direction: row;
        width:          100%;
        height:         100%;

        .childrenContainer
        {
            display:        flex;
            flex-direction: column;
            flex:           1;
            padding:        65px 65px 120px;
            overflow:       auto;
            z-index:        $zIndexChildrenContainer;
        }
    }

    .backgroundLogo,
    .lowerLeftLogo,
    .upperLeftLogo
    {
        z-index:  $zIndexPageBackgroundLogos;
        position: fixed;
        overflow: hidden;

        &.upperLeftLogo
        {
            width: 270px;
            left:  60px;
            top:   40px;
        }

        &.backgroundLogo
        {
            width: 300px;
            top:   60px;
            right: -70px;
        }

        &.lowerLeftLogo
        {
            width:  800px;
            left:   -160px;
            bottom: -400px;
        }
    }
}
