//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$black:          #000000;
$black16:        rgba(0 0 0 / 16%);
$charcoalGrey10: rgba(38 37 38 / 10%);
$charcoalGrey50: rgba(38 37 38 / 50%);
$charcoalGrey:   #262526;
$white80:        rgba(255 255 255 / 80%);
$cherry:         #ca0928;
$gray:           #989898;
$lightGray:      #f9f9f9;
$pinkishGey:     #cbcaca;
$red80:          rgba(202 9 40 / 80%);
$red:            $cherry;
$scarlet:        #bb011d;
$warmGray:       #8f8f8f;
$warmWhite:      #f2f2f2;
$white2:         #f5f5f5;
$white:          #ffffff;
$white40:        rgba(255 255 255 / 40%);

$primaryColor:   $cherry;
