//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import 'styles/all';

*
{
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

body
{
    margin:                  0;
    padding:                 0;
    font-family:             $fontSourceGeneralBody;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include headlineColor();
}

html, body
{
    background-color: $white;
    height:           100%
}

a,
button
{
    text-align:   center;
    touch-action: manipulation;
}

.appContent
{
    width:  100%;
    height: 100%;
}
