@import '../../../../styles/all';

.splide
{
    margin-top:      auto;
    padding:         0 80px;
    justify-content: center;

    .arrow
    {
        $arrowSize:       24px;

        width:            $arrowSize;
        height:           $arrowSize;
        display:          flex;
        align-items:      center;
        justify-content:  center;
        border-radius:    0;
        opacity:          1;
        background-color: transparent;

        svg
        {
            width:  $arrowSize;
            height: $arrowSize;

            @include svgColorFill($scarlet, true);
        }
    }
}

:global
{
    .splide__arrow--prev
    {
        left: 0;
    }

    .splide__arrow--next
    {
        right: 0;
    }
}
