//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$fontsPath:             '../assets/fonts/';
$fontSplineSans:        'Spline Sans', sans-serif;
$fontSourceGeneralBody: $fontSplineSans;

@font-face
{
    font-family: 'Spline Sans';
    src:         url('#{$fontsPath}SplineSans/SplineSans-Regular.eot');
    src:         url('#{$fontsPath}SplineSans/SplineSans-Regular.eot?#iefix') format('embedded-opentype'),
                 url('#{$fontsPath}SplineSans/SplineSans-Regular.woff2') format('woff2'),
                 url('#{$fontsPath}SplineSans/SplineSans-Regular.woff') format('woff'),
                 url('#{$fontsPath}SplineSans/SplineSans-Regular.ttf') format('truetype'),
                 url('#{$fontsPath}SplineSans/SplineSans-Regular.svg#SplineSans-Regular') format('svg');
    font-weight: normal;
    font-style:  normal;
}
