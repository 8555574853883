//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.clipspotsContainer
{
    display:          flex;
    flex-direction:   column;
    position:         relative;
    padding:          45px 35px;
    border-radius:    30px;
    box-shadow:       5px 5px 6px 0 $black16;
    background-color: $lightGray;
    width:            440px;
    height:           380px;
    line-height:      1.2;
    font-size:        24px;
    margin:           10px 0;

    .headline
    {
        font-size:     40px;
        color:         $primaryColor;
        text-align:    left;
        overflow:      hidden;
        white-space:   nowrap;
        text-overflow: ellipsis;
    }

    .subtitle
    {
        font-size:  20px;
        color:      $charcoalGrey;
        text-align: left;
    }

    .image
    {
        $borderRadius: 20px;
        $border:       0.7px;

        position:      relative;
        height:        210px;
        width:         100%;
        border-radius: $borderRadius;
        border:        solid $border $gray;

        .iconImage
        {
            $iconSize: 75px;

            position:  absolute;
            inset:     0 0 0 0;
            margin:    auto;
            width:     $iconSize;
            height:    $iconSize;

            svg
            {
                width:  $iconSize;
                height: $iconSize;

                @include svgColorFill($warmGray);
            }
        }

        img
        {
            border-radius: calc(#{$borderRadius} - #{$border});
            object-fit:    fill;
            width:         100%;
            height:        100%;
        }
    }

    &.small
    {
        padding: 30px 17px;
        width:   225px;
        height:  195px;

        .headline
        {
            font-size:     20px;
            margin-bottom: 5px;
        }

        .image
        {
            height: 105px;
        }
    }

    &.wide
    {
        width: 728px;
    }

    &.full
    {
        width:  100%;
        height: auto;
    }
}

.button
{
    margin-top: 37px;
}

.clickable
{
    cursor: pointer;
}
