//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

$checkBoxSize: 23px;

.checkBoxWrapper
{
    display:        flex;
    flex-direction: column;
}

.checkBoxWrapperNoMarginBottom
{
    margin-bottom: 0;
}

.checkBoxWrapperClickArea
{
    cursor:         pointer;
    display:        flex;
    flex-direction: row;
}

.checkBoxWrapperLabel
{
    color:       $gray;
    font-size:   13px;
    font-weight: $fontWeightRegular;
    margin:      0 0 0 14px;
    user-select: none;
}

.checkBox
{
    width:  $checkBoxSize;
    height: $checkBoxSize;
    border: 2px solid $scarlet;
}

.checkBoxChecked
{
    span
    {
        height: 100%;
    }

    svg
    {
        @include svgColor($scarlet);

        height: 100%;
        width:  100%;
        margin: 0 0 0 0;
    }
}
